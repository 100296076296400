import GameHistoryItem from './GameHistoryItem/GameHistoryItem';
import HistoryTabs from '../shared/HistoryTabs/HistoryTabs';
import Spinner from '../shared/Spinner/Spinner';
import './GameHistoryList.scss';

const tabs = [
  { name: 'Game ID', size: 'col-1' },
  { name: 'Uteral', size: 'col-2' },
  { name: 'Start Price', size: 'col-2' },
  { name: 'End Price', size: 'col-2' },
  { name: 'Result', size: 'col-1' },
  { name: 'Pool Up', size: 'col-2' },
  { name: 'Pool Down', size: 'col-2' }
];

const GameHistoryList = ({ games }) => {
  return (
    <div className='game-history-list mx-auto col-11 p-4'>
      <div className='game-history-container'>
        <HistoryTabs tabs={tabs} />

        <div className='game-history-games px-3'>
          {games ? (
            games.map((game) => <GameHistoryItem key={game.id} gameInfo={game} />)
          ) : (
            <Spinner className='mt-5' />
          )}
        </div>
      </div>
    </div>
  );
};

export default GameHistoryList;

import Navigation from '../../components/shared/Navigation/Navigation';
import './MainLayout.scss';

const MainLayout = ({ disconnect, children }) => {
  return (
    <div className='main-layout position-relative min-vh-100'>
      <div className='d-flex'>
        <Navigation disconnect={disconnect} />
        <div className='main-content flex-grow-1 d-flex justify-content-center'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { token_abi } from '../../../constants/abi';
import { CONTRACT_ADDRESS, TOKEN_CONTRACT_ADDRESS } from '../../../constants/contracts';
import { getContract } from '../../../helpers/ethers';
import Button from '../Button/Button';
import TransactionModal from '../TransactionModal/TransactionModal';
import './BetModal.scss';

const BetModal = ({ show, setShow, library, user, contract, amount, bidType }) => {
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [allowance, setAllowance] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [balance, setBalance] = useState(null);
  const [networkFee, setNetworkFee] = useState(null);

  useEffect(() => {
    if (!library || !user) {
      return;
    }

    setTokenContract(getContract(TOKEN_CONTRACT_ADDRESS, token_abi, library, user));
  }, [library, user])

  useEffect(() => {
    if (!tokenContract) {
      return;
    }

    checkAllowance();
    checkBalance();
  }, [tokenContract])

  useEffect(() => {
    if (!contract || !amount || !bidType) {
      // TODO: Show error or reconnect wallet
      return;
    }

    const gas = contract.estimateGas.putBid(ethers.utils.parseUnits(amount.toString(), 6), bidType === 'up' ? 0 : 1)
      .catch(err => {
        // TODO: Show error message
        console.log(err);
      });

    const gasPrice = library.getGasPrice();

    Promise.all([gas, gasPrice]).then((res) => {
      const fee = res[0].mul(res[1]);
      // 1e11 to shed 11 numbers
      setNetworkFee(ethers.utils.formatUnits(fee.toString(), 'gwei'));
    });
  }, [contract, amount, bidType, library])

  const handleBet = async () => {
    if (!contract || !amount || !bidType) {
      // TODO: Show error or reconnect wallet
      return;
    }

    setShowTransactionModal(true);
    const tx = await contract.putBid(ethers.utils.parseUnits(amount.toString(), 6), bidType === 'up' ? 0 : 1)
      .catch(err => {
        // TODO: Show error message
        console.log(err);
      });

    await tx.wait();

    setShowTransactionModal(false);
    setShow(false);
    // TODO: Show success message
  };

  const handleApprove = async () => {
    setShowTransactionModal(true);
    const tx = await tokenContract.approve(CONTRACT_ADDRESS, ethers.constants.MaxUint256)
      .catch(err => {
        // TODO: Show error message
        console.log(err);
      });

    await tx.wait();

    setShowTransactionModal(false);
    checkAllowance();
  }

  const checkBalance = () => {
    tokenContract.balanceOf(user)
      .then(balance => {
        setBalance(ethers.utils.formatUnits(balance.toString(), 6).toString());
      })
      .catch(err => {
        console.log(err);
      });
  }

  const checkAllowance = () => {
    tokenContract.allowance(user, CONTRACT_ADDRESS)
      .then(allowance => {
        if (allowance.eq(ethers.constants.MaxUint256)) {
          setAllowance(true);
        } else {
          setAllowance(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  if (showTransactionModal) {
    return <TransactionModal show={showTransactionModal} />;
  }

  if (allowance === null) {
    return (
      <Modal show={show} size='sm' centered className='bet-modal'>
        <Modal.Header className='d-flex justify-content-center border-0'>
          <Modal.Title className='fw-bold'>Loading</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 fw-500'>
          <Spinner animation='border' className='me-3 transaction-spinner' />
        </Modal.Body>
      </Modal>
    )
  }

  if (allowance === false) {
    return (
      <Modal show={show} size='sm' centered className='bet-modal'>
        <Modal.Header className='d-flex justify-content-center border-0'>
          <Modal.Title className='fw-bold'>Approve NextBid</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 fw-500'>
          <p>You need to approve that NextBid can interact with your BUSD tokens.</p>
          <p>This must be done only once!</p>
          <Button className='mt-3 bet-confirm-btn' onClick={handleApprove}>
            Approve
          </Button>
          <button
            className='d-block mx-auto cancel-btn mt-1'
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    )
  }


  return (
    <Modal show={show} size='sm' centered className='bet-modal'>
      <Modal.Header className='d-flex justify-content-center border-0'>
        <Modal.Title className='fw-bold'>Bet: ${amount}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-0 fw-500'>
        <div className='d-flex justify-content-between'>
          <p>Your balance: </p> <p>${balance}</p>
        </div>
        <div className='d-flex justify-content-between'>
          <p>Network fee: </p> <p>{networkFee || '-'} ETH</p>
        </div>

        <Button className='mt-3 bet-confirm-btn' onClick={handleBet}>
          Bet
        </Button>
        <button
          className='d-block mx-auto cancel-btn mt-1'
          onClick={() => setShow(false)}
        >
          Cancel
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default BetModal;

import { useState, useEffect } from 'react';
import { getUserAvailableWithdraws } from '../../services/userService';
import WithdrawList from '../../components/WithdrawList/WithdrawList';
import './Withdraw.scss';

const Withdraw = ({ address, library, contract }) => {
  const [games, setGames] = useState(null);
  useEffect(() => {
    getUserAvailableWithdraws(address, { address }).then(setGames).catch(console.log);
  }, [address]);

  return (
    <div className='withdraw-page flex-grow-1 mw-100'>
      <div className='page-background position-absolute min-vh-100 min-vw-100'></div>
      <WithdrawList games={games} library={library} contract={contract} />
    </div>
  );
};

export default Withdraw;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Sling as Hamburger } from 'hamburger-react';
import { ReactComponent as Logo } from '../../../assets/logo-white.svg';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg';
import { ReactComponent as Graph } from '../../../assets/icons/graph.svg';
import { ReactComponent as Withdraw } from '../../../assets/icons/withdraw.svg';
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg';
import { ReactComponent as User } from '../../../assets/icons/user.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg';
import NavOption from './NavOption/NavOption';
import NavDropdown from './NavDropdown/NavDropdown';
import { mapOptions } from '../../../helpers/navigation';
import './Navigation.scss';

const Navigation = ({ disconnect }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [options, setOptions] = useState([
    { text: 'Home', link: '/', icon: <Home /> },
    { text: 'Market Data', link: '/market', icon: <Graph /> },
    { text: 'Withdraw', link: '/withdraw', icon: <Withdraw /> },
    {
      text: 'History',
      icon: <Clock />,
      options: [
        { text: 'Game History', link: '/game-history', icon: <Calendar /> },
        { text: 'Personal History', link: '/personal-history', icon: <User /> }
      ]
    }
  ]);

  useEffect(() => {
    setOptions((options) => mapOptions(options, location));
  }, [location]);

  return (
    <>
      <div className='burger-wrapper'>
        <Hamburger
          toggled={open}
          toggle={setOpen}
          color={'#fdfdfd'}
          size={32}
          duration={0.3}
        />
      </div>
      <aside className={`navigation-wrapper flex-shrink-0 pb-4 ${open ? 'nav-open' : ''}`}>
        <div className='logo-container text-center mx-auto'>
          <Logo className='logo' />
          <div className='logo-container-line mt-2' />
        </div>
        <div className='navigation-container mx-auto mt-4'>
          {options.map((x, i) =>
            !x.options ? (
              <NavOption key={i} text={x.text} link={x.link} selected={x.selected}>
                {x.icon}
              </NavOption>
            ) : (
              <NavDropdown key={i} text={x.text} options={x.options}>
                <Clock />
              </NavDropdown>
            )
          )}
        </div>
        <div className='logout-container d-flex justify-content-center position-absolute w-100'>
          <button
            className='logout-btn bg-transparent border-0 d-flex align-items-center'
            onClick={disconnect}
          >
            <Logout />
            Sign out
          </button>
        </div>
      </aside>
    </>
  );
};

export default Navigation;

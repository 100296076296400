import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import { ethers } from 'ethers';

export const bigNumber = (x) => ethers.BigNumber.from(x);

export function getContract(address, ABI, library, account) {
  if (!isAddress(address) || address === AddressZero) {
    throw new Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function signMessage(library, account, nonce) {
  const signer = getSigner(library, account);

  return signer.signMessage(nonce).catch((error) => console.log(error));
}

function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { ethers } from 'ethers';
import BetModal from '../shared/BetModal/BetModal';
import BetButton from './BetButton/BetButton';
import { ReactComponent as AngleDown } from '../../assets/icons/angle-down.svg';
import { ReactComponent as AngleTop } from '../../assets/icons/angle-top.svg';
import moment from 'moment';
import './BetGame.scss';

const betOptions = [1, 10, 100, 1000];

const BetGame = ({ library, address, contract }) => {
  const [selectedMovement, setSelectedMovement] = useState(null);
  const [sum, setSum] = useState(null);
  const [game, setGame] = useState(null);
  const [bidEnds, setBidEnds] = useState(null);

  useEffect(() => {
    if (!contract) {
      return;
    }

    contract
      .biddingGameId()
      .then((id) => {
        contract.games(id).then((game) => {
          setGame(game);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contract]);

  useEffect(() => {
    if (!game) {
      return;
    }

    let lastValue = bidEndsIn(game.priceCheckStartTime);
    setBidEnds(lastValue);
    const interval = setInterval(() => {
      lastValue = moment(lastValue).subtract(1, 'seconds');
      setBidEnds(lastValue);
    }, 1000);

    return () => clearInterval(interval);
  }, [game]);

  function toLocalTime(timestamp) {
    return moment(timestamp.toNumber() * 1000).format('hh:mm A');
  }

  function bidEndsIn(priceCheckStartTime) {
    // Bid ends 10 minutes before 'priceCheckStartTime', but here the end is 2 minutes earlier,
    // so smaller chance for tx to be reverted, if it is mined in later block
    const end = moment(priceCheckStartTime.toNumber() * 1000).subtract(12, 'minutes');
    const now = moment();
    return end.diff(now);
  }

  if (!game) {
    return <Spinner animation='border' className='mx-auto spinner-primary d-block' />;
  }

  if (bidEnds < 1) {
    return (
      <div className='bg-white mx-auto col-11 bet-game-wrapper'>
        <div className='bet-game-container mx-auto my-2'>
          <h6 className='text-center fw-bold pb-2'>
            Next game is starting soon! Please wait...
          </h6>
        </div>
      </div>
    );
  }

  return (
    <>
      <BetModal
        show={sum && selectedMovement}
        setShow={setSum}
        user={address}
        library={library}
        contract={contract}
        amount={sum}
        bidType={selectedMovement}
      />
      <div className='bg-white mx-auto col-11 bet-game-wrapper'>
        <div className='bet-game-container mx-auto my-2'>
          <h6 className='text-center fw-bold pb-2'>
            Game: {toLocalTime(game.priceCheckStartTime)} -{' '}
            {toLocalTime(game.priceCheckEndTime)}
          </h6>
          <h4 className='text-center fw-bold my-4'>
            Bids add in: {bidEnds ? moment.utc(bidEnds).format('HH:mm:ss') : '-'}
          </h4>

          <div className='bet-choice-container mx-auto d-flex justify-content-between py-2'>
            <div className='down-btn-wrapper'>
              <h6 className='bet-choice-text text-center mb-3'>Price: Down</h6>
              <button
                className={`down-btn bet-choice-btn ${
                  selectedMovement === 'down' ? 'selected' : ''
                }`}
                onClick={() => setSelectedMovement('down')}
              >
                <AngleDown />
              </button>
            </div>

            <div className='up-btn-wrapper'>
              <h6 className='bet-choice-text text-center mb-3'>Price: Up</h6>
              <button
                className={`up-btn bet-choice-btn ${
                  selectedMovement === 'up' ? 'selected' : ''
                }`}
                onClick={() => setSelectedMovement('up')}
              >
                <AngleTop />
              </button>
            </div>
          </div>

          <div className='bet-amount my-2'>
            <h6 className='text-center fw-bold mb-2 pb-2'>Bet:</h6>
            {betOptions.map((x) => (
              <BetButton
                key={x}
                value={x}
                onClick={() => setSum(x)}
                selected={sum === x}
              />
            ))}
          </div>

          <div className='price-pool-container my-2'>
            <h6 className='price-pool-title text-center fw-bold mt-2'>Price Pool:</h6>
            <p className='price-pool-value text-center'>
              $
              {ethers.utils.formatUnits(game.amountUp.add(game.amountDown).toString(), 6)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BetGame;

import BetGame from '../../components/BetGame/BetGame';
import './Home.scss';

const Home = ({ library, address, contract }) => {
  return (
    <div className='home-page flex-grow-1'>
      <div className='page-background position-absolute min-vh-100 min-vw-100'></div>
      <BetGame library={library} address={address} contract={contract} />
    </div>
  );
};

export default Home;

if (!process.env.REACT_APP_DEFAULT_NETWORK) {
  throw new Error(`Env variable 'REACT_APP_DEFAULT_NETWORK' is missing`);
}

if (!process.env.REACT_APP_CHAIN_ID) {
  throw new Error(`Env variable 'REACT_APP_CHAIN_ID' is missing`);
}

if (!process.env.REACT_APP_INFURA_ID) {
  throw new Error(`Env variable 'REACT_APP_INFURA_ID' is missing`);
}

export const DEFAULT_NETWORK = process.env.REACT_APP_DEFAULT_NETWORK;
export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export const NETWORKS = {
  42: 'kovan',
  56: 'bsc',
};

import WithdrawItem from './WithdrawItem/WithdrawItem';
import HistoryTabs from '../shared/HistoryTabs/HistoryTabs';
import Spinner from '../shared/Spinner/Spinner';
import './WithdrawList.scss';

const tabs = [
  { name: 'Game ID', size: 'col-3' },
  { name: 'Bid', size: 'col-3' },
  { name: 'Total', size: 'col-3' },
  { name: 'Actions', size: 'col-3' }
];

const WithdrawList = ({ games, library, contract }) => {
  return (
    <div className='withdraw-list mx-auto col-11 p-4'>
      <div className='withdraw-container'>
        <HistoryTabs tabs={tabs} />

        <div className='withdraw-games px-3 pt-3'>
          {games ? (
            games.map((game) => (
              <WithdrawItem
                key={game.id}
                gameInfo={game}
                library={library}
                contract={contract}
              />
            ))
          ) : (
            <Spinner className='mt-5' />
          )}
        </div>
      </div>
    </div>
  );
};

export default WithdrawList;

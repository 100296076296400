import Modal from 'react-bootstrap/Modal';
import Button from '../Button/Button';
import './NetworkModal.scss';

const NetworkModal = ({
  show,
  setShow,
  title,
  description,
  network,
  disconnect,
  handleNetworkSwitch
}) => {
  return (
    <Modal show={show} size='md' centered className='network-modal'>
      <Modal.Header className='d-flex justify-content-center border-0'>
        <Modal.Title className='fw-bold'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <h6 className='mb-3 text-center'>{description}</h6>
        {window.ethereum ? (
          <Button
            onClick={() => handleNetworkSwitch(network)}
            className='mb-3 switch-btn'
          >
            Switch to {network}
          </Button>
        ) : null}
        <button
          onClick={() => {
            disconnect();
            setShow(false);
          }}
          className='d-block mx-auto logout-btn'
        >
          Sign Out
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default NetworkModal;

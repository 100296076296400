import { useState, useEffect } from 'react';
import { getUserGames } from '../../services/userService';
import PersonalHistoryList from '../../components/PersonalHistoryList/PersonalHistoryList';
import './PersonalHistory.scss';

const PersonalHistory = ({ address }) => {
  const [games, setGames] = useState(null);
  useEffect(() => {
    getUserGames(address).then(setGames).catch(console.log);
  }, [address]);

  return (
    <div className='personal-history-page flex-grow-1 mw-100'>
      <div className='page-background position-absolute min-vh-100 min-vw-100'></div>
      <PersonalHistoryList games={games} />
    </div>
  );
};

export default PersonalHistory;

import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import './Spinner.scss';

const Spinner = ({ className }) => {
  return (
    <BootstrapSpinner
      animation='border'
      className={`mx-auto spinner-primary d-block ${className}`}
    />
  );
};

export default Spinner;

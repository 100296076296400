import './Button.scss';

const Button = ({ children, className = '', onClick = () => null }) => {
  return (
    <button
      className={`custom-btn fw-bold w-100 mx-auto d-block ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;

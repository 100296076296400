if (!process.env.REACT_APP_CONTRACT_ADDRESS) {
  throw new Error(`Env variable 'REACT_APP_CONTRACT_ADDRESS' is missing`);
}

if (!process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS) {
  throw new Error(`Env variable 'REACT_APP_TOKEN_CONTRACT_ADDRESS' is missing`);
}

export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
export const TOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;

import { Link } from 'react-router-dom';
import './NavOption.scss';

const NavOption = ({ text, link, children, selected }) => {
  return (
    <Link
      className={`fw-500 py-3 px-3 nav-option d-flex align-items-center mb-1 ${
        selected ? 'selected' : ''
      }`}
      to={link}
    >
      {children}
      {text}
    </Link>
  );
};

export default NavOption;

import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import './TransactionModal.scss';

const TransactionModal = ({ show }) => {
  return (
    <Modal show={show} size='md' centered className='transaction-modal'>
      <Modal.Header className='border-bottom-0'>
        <Modal.Title className='d-flex align-items-center'>
          <Spinner animation='border' className='me-3 transaction-spinner' />
          Checking transaction...
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <p>This may take several minutes.</p>
        <p className='fw-500'>
          Please don’t close this window until the process is done.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionModal;

import { ReactComponent as Logo } from '../../assets/logo.svg';
import './Landing.scss';

const Landing = ({ onConnect }) => {
  return (
    <div className='landing-page row d-flex justify-content-center mx-0'>
      <div className='col-lg-8 col-md-7 landing-page-background min-vh-100 flex-grow-1' />
      <aside className='sidebar col-lg-4 col-md-5 text-center'>
        <div className='pt-5'>
          <Logo className='logo' />
        </div>
        <button className='d-block mx-auto my-5 connect-btn fw-bold' onClick={onConnect}>
          Connect
        </button>
      </aside>
    </div>
  );
};

export default Landing;

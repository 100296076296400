export const mapOptions = (options, location) => {
  return options.map((x) => {
    if (x.link) {
      x.selected = location.pathname.replace(/\//g, '') === x.link.replace(/\//g, '');
    } else {
      x.options = mapOptions(x.options, location);
    }

    return x;
  });
};

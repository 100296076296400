import { ethers } from 'ethers';
import { getGameId } from '../../../helpers/game';
import HistoryPeriod from '../../shared/HistoryPeriod/HistoryPeriod';
import HistoryResult from '../../shared/HistoryResult/HistoryResult';
import './PersonalHistoryItem.scss';

const PersonalHistoryItem = ({ gameInfo }) => {
  return (
    <div className='personal-history-item d-flex align-items-center justify-content-between fw-500 text-center py-3'>
      <div className='game-id col-1'>
        <p>#{getGameId(gameInfo.id)}</p>
      </div>

      <HistoryPeriod
        startTime={gameInfo.priceCheckStartTime * 1000}
        endTime={gameInfo.priceCheckEndTime * 1000}
      />

      <div className='start-price col-2'>
        <p>
          {gameInfo.endPrice ? ethers.utils.formatUnits(gameInfo.startPrice, 8) : '-'}
        </p>
      </div>

      <div className='end-price col-2'>
        <p>{gameInfo.endPrice ? ethers.utils.formatUnits(gameInfo.endPrice, 8) : '-'}</p>
      </div>

      <div className='bid col-1'>
        <p>{ethers.utils.formatUnits(gameInfo.bidsTotal, 6)}</p>
      </div>

      <HistoryResult result={gameInfo.winner} />

      <div className='profit col-2'>
        <p>
          {gameInfo.winner !== 0
            ? ethers.utils.formatUnits(gameInfo.profit, 6) || '-'
            : '-'}
        </p>
      </div>
    </div>
  );
};

export default PersonalHistoryItem;

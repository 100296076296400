import { ethers } from 'ethers';
import { useState } from 'react';
import { getGameId } from '../../../helpers/game';
import WithdrawModal from '../../shared/WithdrawModal/WithdrawModal';
import './WithdrawItem.scss';

const WithdrawItem = ({ gameInfo, library, contract }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <WithdrawModal
        show={show}
        setShow={setShow}
        library={library}
        contract={contract}
        gameInfo={gameInfo}
      />
      <div className='withdraw-item d-flex align-items-center fw-500 text-center py-2'>
        <div className='game-id col-3'>
          <p>#{getGameId(gameInfo.id)}</p>
        </div>

        <div className='bid col-3'>
          <p>{ethers.utils.formatUnits(gameInfo.bidsTotal, 6)}</p>
        </div>

        <div className='total col-3'>
          <p>{ethers.utils.formatUnits(gameInfo.winningsTotal, 6)}</p>
        </div>

        <div className='actions col-3'>
          <button className='fw-500' onClick={() => setShow(true)}>
            Withdraw
          </button>
        </div>
      </div>
    </>
  );
};

export default WithdrawItem;

import moment from 'moment';
import './HistoryPeriod.scss';

const HistoryPeriod = ({ startTime, endTime }) => {
  return (
    <div className='history-period col-2'>
      <p className='period-date'>
        {moment(startTime).format('DD.MM.YY')} - {moment(endTime).format('DD.MM.YY')}
      </p>
      <p className='period-time'>
        {moment(startTime).format('hh A')} - {moment(endTime).format('hh A')}
      </p>
    </div>
  );
};

export default HistoryPeriod;

import { useState, useEffect } from 'react';
import { getGames } from '../../services/gameService';
import GameHistoryList from '../../components/GameHistoryList/GameHistoryList';
import './GameHistory.scss';

const GameHistory = () => {
  const [games, setGames] = useState(null);
  useEffect(() => {
    getGames().then(setGames).catch(console.log);
  }, []);

  return (
    <div className='game-history-page flex-grow-1 mw-100'>
      <div className='page-background position-absolute min-vh-100 min-vw-100'></div>
      <GameHistoryList games={games} />
    </div>
  );
};

export default GameHistory;

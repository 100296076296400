export const userBidsQuery = `
  query ($address: String) {
    bids (where: {user: $address}) {
      amount
      bidType
      game {
        id
        priceCheckStartTime
        priceCheckEndTime
        startPrice
        endPrice
        amountPoolUp
        amountPoolDown
        winner 
      }
    }
  }
`;

export const userBidsShortQuery = `
  query ($address: String) {
    bids (where: {user: $address}) {
      amount
      bidType
      game {
        id
        amountPoolUp
        amountPoolDown
        winner 
      }
    }
  }
`;

export const gamesQuery = `
  query {
    games {
      id
      priceCheckStartTime
      priceCheckEndTime
      startPrice
      endPrice
      amountPoolUp
      amountPoolDown
      winner
    }
  }
`;

import { ethers } from 'ethers';
import { getGameId } from '../../../helpers/game';
import HistoryPeriod from '../../shared/HistoryPeriod/HistoryPeriod';
import HistoryResult from '../../shared/HistoryResult/HistoryResult';
import './GameHistoryItem.scss';

const GameHistoryItem = ({ gameInfo }) => {
  return (
    <div className='game-history-item d-flex align-items-center fw-500 text-center py-3'>
      <div className='game-id col-1'>
        <p>#{getGameId(gameInfo.id)}</p>
      </div>

      <HistoryPeriod
        startTime={gameInfo.priceCheckStartTime * 1000}
        endTime={gameInfo.priceCheckEndTime * 1000}
      />

      <div className='start-price col-2'>
        <p>
          {gameInfo.startPrice ? ethers.utils.formatUnits(gameInfo.startPrice, 8) : '-'}
        </p>
      </div>

      <div className='end-price col-2'>
        <p>{gameInfo.endPrice ? ethers.utils.formatUnits(gameInfo.endPrice, 8) : '-'}</p>
      </div>

      <HistoryResult result={gameInfo.winner} />

      <div className='pool-up col-2'>
        <p>{ethers.utils.formatUnits(gameInfo.amountPoolUp, 6) || '-'}</p>
      </div>

      <div className='pool-down col-2'>
        <p>{ethers.utils.formatUnits(gameInfo.amountPoolDown, 6) || '-'}</p>
      </div>
    </div>
  );
};

export default GameHistoryItem;

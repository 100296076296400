import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../Button/Button';
import TransactionModal from '../TransactionModal/TransactionModal';
import './WithdrawModal.scss';

const WithdrawModal = ({ show, setShow, library, contract, gameInfo }) => {
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [networkFee, setNetworkFee] = useState('');

  useEffect(() => {
    if (!contract || !gameInfo.id || !library) {
      // TODO: Show error or reconnect wallet
      return;
    }

    const gas = contract.estimateGas.claimReward(gameInfo.id).catch((err) => {
      // TODO: Show error message
      console.log(err);
    });

    const gasPrice = library.getGasPrice();

    Promise.all([gas, gasPrice]).then((res) => {
      const fee = res[0].mul(res[1]);
      // 1e11 to shed 11 numbers
      setNetworkFee(ethers.utils.formatUnits(fee.toString()));
    });
  }, [contract, gameInfo.id, library]);

  const handleWithdraw = async (gameId) => {
    if (!contract) {
      return;
    }

    setShowTransactionModal(true);
    const tx = await contract.claimReward(gameInfo.id).catch((err) => {
      // TODO: Show error message
      console.log(err);
    });

    await tx.wait();

    setShowTransactionModal(false);
    setShow(false);
    // TODO: Show success message
  };
  return (
    <>
      <TransactionModal show={showTransactionModal} />
      <Modal show={show} size='sm' centered className='withdraw-modal'>
        <Modal.Header className='d-flex justify-content-center border-0'>
          <Modal.Title className='fw-bold'>
            Withdraw: ${ethers.utils.formatUnits(gameInfo.winningsTotal, 6)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 fw-500'>
          <div className='d-flex justify-content-between'>
            <p>Network fee: </p> <p>{networkFee || '-'} ETH</p>
          </div>

          <Button className='mt-3 withdraw-confirm-btn' onClick={handleWithdraw}>
            Withdraw
          </Button>
          <button
            className='d-block mx-auto cancel-btn mt-1'
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawModal;

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-broken-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-broken-up.svg';
import './HistoryResult.scss';

const results = ['In Progress', 'Up', 'Down', 'Tie'];

const HistoryResult = ({ result }) => {
  return (
    <div className='history-result col-1 d-flex align-items-center justify-content-center'>
      {result === 1 ? (
        <ArrowUp className='arrow-up' />
      ) : result === 2 ? (
        <ArrowDown className='arrow-down' />
      ) : null}
      <p className='ms-2 text-capitalize'>{results[result]}</p>
    </div>
  );
};

export default HistoryResult;

import { queryData } from './queryService';
import { userBidsQuery, userBidsShortQuery } from '../queries/userQueries';
import { bigNumber } from '../helpers/ethers';

export const getUserGames = async (address) => {
  const graphData = await queryData(userBidsQuery, { address });
  const games = mapBidsToGames(graphData.data.bids);
  return games.sort((a, b) => b.priceCheckStartTime - a.priceCheckStartTime);
};

export const getUserAvailableWithdraws = async (address) => {
  const graphData = await queryData(userBidsShortQuery, { address });
  const games = mapBidsToGames(graphData.data.bids);
  return games.filter((x) => x.winningsTotal > 0);
};

const mapBidsToGames = (bids) => {
  const games = bids.reduce((acc, cur) => {
    const gameId = cur.game.id;
    const { amount, bidType } = cur;

    let rewardPool = bigNumber(cur.game.amountPoolUp).add(
      bigNumber(cur.game.amountPoolDown)
    );
    let winnersPool;
    if (bidType === 0 && cur.game.winner === 1) {
      // price Up
      winnersPool = bigNumber(cur.game.amountPoolUp);
    } else if (bidType === 1 && cur.game.winner === 2) {
      // price Down
      winnersPool = bigNumber(cur.game.amountPoolDown);
    } else if (cur.game.winner === 3) {
      // Tie
      winnersPool = bigNumber(cur.game.amountPoolUp).add(
        bigNumber(cur.game.amountPoolDown)
      );
    }

    let userReward = winnersPool
      ? bigNumber(amount).div(winnersPool).mul(rewardPool) || bigNumber('0')
      : bigNumber('0');

    if (acc[gameId]) {
      acc[gameId].bids.push({ amount, bidType, userReward });
    } else {
      acc[gameId] = { ...cur.game };
      acc[gameId].bids = [{ amount, bidType, userReward }];
    }
    return acc;
  }, {});

  const gamesArr = Object.keys(games).map((key) => {
    const winningsTotal = games[key].bids.reduce(
      (acc, cur) => acc.add(bigNumber(cur.userReward)),
      bigNumber('0')
    );
    const bidsTotal = games[key].bids.reduce(
      (acc, cur) => acc.add(bigNumber(cur.amount)),
      bigNumber('0')
    );
    const profit = winningsTotal.sub(bidsTotal);

    return { id: key, ...games[key], profit, bidsTotal, winningsTotal };
  });

  return gamesArr;
};

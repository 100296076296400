import './BetButton.scss';

const BetButton = ({ value, selected, onClick = () => null }) => {
  return (
    <button className={`bet-btn w-100 fw-bold mb-2 ${selected ? 'selected' : ''}`} onClick={onClick}>
      ${value}
    </button>
  );
};

export default BetButton;

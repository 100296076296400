import { useState } from 'react';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow-broken-down.svg';
import NavOption from '../NavOption/NavOption';
import './NavDropdown.scss';

const NavDropdown = ({ text, children, options }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        className={`${
          open ? 'open' : ''
        } w-100 fw-500 p-3 d-flex align-items-center nav-dropdown mb-1`}
      >
        {children}
        <div className='d-flex w-100 justify-content-between align-items-center'>
          {text}
          <Arrow className='dropdown-arrow me-1' />
        </div>
      </button>
      {open &&
        options.map((x, i) => (
          <NavOption key={i} text={x.text} link={x.link} selected={x.selected}>
            {x.icon}
          </NavOption>
        ))}
    </>
  );
};

export default NavDropdown;

import './HistoryTabs.scss';

const HistoryTabs = ({ tabs }) => {
  return (
    <div className='history-tabs d-flex px-3 justify-content-between'>
      {tabs.map((tab) => (
        <div key={tab.name} className={`history-tab ${tab.size}`}>
          <h6 className='fw-bold text-center'>{tab.name}</h6>
        </div>
      ))}
    </div>
  );
};

export default HistoryTabs;

import { useEffect } from 'react';
import './MarketData.scss';

const MarketData = () => {
  useEffect(() => {
    new window.TradingView.widget({
      autosize: true,
      symbol: 'COINBASE:ETHUSD',
      interval: 'D',
      timezone: 'Etc/UTC',
      theme: 'light',
      style: '1',
      locale: 'en',
      toolbar_bg: '#fdfdfd',
      enable_publishing: false,
      allow_symbol_change: true,
      container_id: 'tradingview_container'
    });
  }, []);

  return (
    <div className='market-data-page flex-grow-1 h-100'>
      <div className='page-background position-absolute min-vh-100 min-vw-100'></div>
      {/* <!-- TradingView Widget BEGIN --> */}
      <div className='tradingview-widget-container pe-4 h-100'>
        <div id='tradingview_container' className='mx-auto'></div>
        <div className='tradingview-widget-copyright'>
          <a
            href='https://www.tradingview.com/symbols/ETHUSD/?exchange=COINBASE'
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className='blue-text'>ETHUSD Chart</span>
          </a>{' '}
          by TradingView
        </div>
      </div>
      {/* <!-- TradingView Widget END --> */}
    </div>
  );
};

export default MarketData;

import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ethers } from 'ethers';
import { CHAIN_ID, NETWORKS } from './constants';
import { supportedChains } from './constants/chains';
import { createWeb3Modal } from './utils/web3modal';
import AppRoutes from './Routes';
import NetworkModal from './components/shared/NetworkModal/NetworkModal';
import { getContract } from './helpers/ethers';
import { CONTRACT_ADDRESS } from './constants/contracts';
import { abi } from './constants/abi';
import './App.scss';

let web3Modal;
const App = () => {
  const [chainId, setChainId] = useState(CHAIN_ID);
  const [address, setAddress] = useState();
  const [library, setLibrary] = useState(null);
  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);
  const [networkModal, setNetworkModal] = useState(false);

  useEffect(() => {
    onStart();
    web3Modal = createWeb3Modal();
    if (web3Modal.cachedProvider) {
      onConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!library || !address) {
      return;
    }

    const contract = getContract(CONTRACT_ADDRESS, abi, library, address);
    setContract(contract);
  }, [library, address]);

  useEffect(() => {
    setNetworkModal(chainId.toString() !== CHAIN_ID.toString());
  }, [chainId]);

  useEffect(() => {
    if (address) {
      localStorage.setItem('address', address);
    } else if (address === null) {
      localStorage.removeItem('address', address);
    }
  }, [address]);

  async function onStart() {
    const provider = new ethers.providers.getDefaultProvider(NETWORKS[CHAIN_ID]);
    setLibrary(provider);
  }

  async function onConnect() {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const network = await library.getNetwork();
      const address = provider.selectedAddress
        ? provider.selectedAddress
        : provider.accounts[0];
      setProvider(provider);
      setLibrary(library);
      setAddress(address);
      setChainId(network.chainId);
      if (network.chainId.toString() !== CHAIN_ID.toString()) {
        setNetworkModal(true);
      }

      localStorage.setItem('address', address);

      await subscribeToProviderEvents(provider);
    } catch (error) {
      await disposeCachedProvider();
    }
  }

  async function subscribeToProviderEvents(provider) {
    if (!provider.on) {
      return;
    }

    provider.on('accountsChanged', changedAccounts);
    provider.on('chainChanged', chainChanged);
    provider.on('disconnect', resetApp);
  }

  function chainChanged(_chainId) {
    window.location.reload();
  }

  const unSubscribe = async (provider) => {
    if (!provider || !provider.removeListener) {
      return;
    }

    provider.removeListener('accountsChanged', changedAccounts);
    provider.removeListener('chainChanged', chainChanged);
    provider.removeListener('disconnect', resetApp);
  };

  async function changedAccounts(accounts) {
    if (!accounts.length) {
      // Metamask Lock fires an empty accounts array
      await resetApp();
      return;
    }

    setAddress(accounts[0]);
  }

  const disposeCachedProvider = async () => {
    await web3Modal.clearCachedProvider();
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    localStorage.removeItem('walletconnect');
    localStorage.removeItem('bt');
    localStorage.removeItem('address');
    setProvider(null);
    setLibrary(null);
    setAddress(null);

    await unSubscribe(provider);
  };

  async function resetApp() {
    await disposeCachedProvider();
  }

  async function disconnect() {
    if (library.provider?.disconnect) {
      library.provider.disconnect().catch((err) => {
        console.log(err);
      });
    }
    resetApp();
  }

  const handleNetworkSwitch = async (networkName) => {
    await changeNetwork(networkName);
    setNetworkModal(false);
  };

  const changeNetwork = async (networkName) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(CHAIN_ID).toString(16)}` }]
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...supportedChains[networkName]
              }
            ]
          });
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  };

  return (
    <BrowserRouter>
      <div className='App'>
        <NetworkModal
          title={'Wrong Network'}
          description={`You need to switch to ${NETWORKS[CHAIN_ID]} network!`}
          network={`${NETWORKS[CHAIN_ID]}`}
          show={networkModal}
          setShow={setNetworkModal}
          disconnect={disconnect}
          handleNetworkSwitch={handleNetworkSwitch}
        />
        <AppRoutes onConnect={onConnect} disconnect={disconnect} library={library} address={address} contract={contract} />
      </div>
    </BrowserRouter>
  );
};

export default App;

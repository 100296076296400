import { createClient } from 'urql';

const api = process.env.REACT_APP_SUBGRAPH_API;

const client = createClient({
  url: api,
  requestPolicy: 'cache-and-network'
});

export const queryData = async (query, variables) => {
  return await client.query(query, variables).toPromise();
};

import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Landing from './pages/Landing/Landing';
import MainLayout from './layouts/MainLayout/MainLayout';
import MarketData from './pages/MarketData/MarketData';
import Withdraw from './pages/Withdraw/Withdraw';
import GameHistory from './pages/GameHistory/GameHistory';
import PersonalHistory from './pages/PersonalHistory/PersonalHistory';

const AppRoutes = ({ onConnect, disconnect, library, address, contract }) => {
  return (
    <Routes>
      {localStorage.getItem('address') ? (
        <Route
          element={
            <MainLayout disconnect={disconnect}>
              <Outlet />
            </MainLayout>
          }
        >
          <Route
            path='/'
            element={<Home library={library} address={address} contract={contract} />}
          />
          <Route path='/market' element={<MarketData />} />
          <Route
            path='/withdraw'
            element={
              <Withdraw
                address={address}
                library={library}
                contract={contract}
              />
            }
          />
          <Route path='/game-history' element={<GameHistory />} />
          <Route
            path='/personal-history'
            element={<PersonalHistory address={address} />}
          />
        </Route>
      ) : (
        <Route path='/' exact element={<Landing onConnect={onConnect} />} />
      )}
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  );
};

export default AppRoutes;

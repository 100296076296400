import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { CHAIN_ID, INFURA_ID } from '../constants';

export const createWeb3Modal = () => {
  const modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: getProviderOptions()
  });
  return modal;
};

const getProviderOptions = () => {
  const providerOptions = {};
  providerOptions.walletconnect = {
    package: WalletConnectProvider,
    options: {
      infuraId: INFURA_ID,
      chainId: CHAIN_ID
    }
  };

  return providerOptions;
};
